<template>
  <div
    class="horizontal-layout"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >
    <b-navbar class="d-flex justify-content-center navbar-fixed">
      <span class="brand-logo d-flex justify-content-center">
        <b-img
          :src="appLogoImage"
          alt="logo"
          class="logo"
        />
      </span>


      <b-navbar-nav class="nav align-items-center ml-auto" style="z-index:1000;">
        <user-dropdown />
      </b-navbar-nav>
    </b-navbar>

    <div class="horizontal-menu-wrapper">
      <div
        class="header-navbar navbar navbar-horizontal navbar-light navbar-shadow menu-border"
        :class="[navbarMenuTypeClass]"
      >
      <b-container class="d-flex aling-items-center justify-content-center">
        <b-row class="">
          <b-col cols="12" sm="" class="d-flex flex-column flex-sm-row">

            <router-link
              to="/overzicht"
              custom
              v-slot="{ navigate, isActive }"
                class="mt-1 mt-sm-0 m-sm-1" 
            >
              <b-button
                @click="navigate"
                variant="info"
                :active="isActive" 
                :class="isActive ? 'active': ''"
              >
                Overzicht
              </b-button>
            </router-link>

            <router-link
              to="/familiebeheer/relaties"
              v-if="$auth.user && $auth.hasRole(['admin', 'superadmin'])" 
              custom
              v-slot="{ navigate, isActive }"
                class="mt-1 mt-sm-0 m-sm-1" 
            >
              <b-button
                @click="navigate"
                variant="info"
                :active="isActive" 
                :class="isActive ? 'active': ''"
              >
                Familiebeheer
              </b-button>
            </router-link>

            <router-link
              to="/jaaropgave"
              v-if="$auth.user && $auth.hasRole(['sender', 'superadmin'])" 
              custom
              v-slot="{ navigate, isActive }"
                class="mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1" 
            >
              <b-button
                @click="navigate"
                variant="info"
                :active="isActive" 
                :class="isActive ? 'active': ''"
              >
                Jaaropgave
              </b-button>
            </router-link>

            <router-link
              to="/admin"
              v-if="$auth.user && $auth.hasRole(['superadmin'])" 
              custom
              v-slot="{ navigate, isActive }"
                class="mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1" 
            >
              <b-button
                @click="navigate"
                variant="secondary"
                :active="isActive" 
                :class="isActive ? 'active': ''"
              >
                Admin
              </b-button>
            </router-link>
          </b-col>
        </b-row>
      </b-container>
      </div>
    </div>

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'

import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@/layouts/horizontal/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar, BNavbarNav, BImg, BLink, BRow, BCol, BContainer, BButton } from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import useLayoutHorizontal from '@core/layouts/layout-horizontal/useLayoutHorizontal'
import HorizontalNavMenu from '@core/layouts/layout-horizontal/components/horizontal-nav-menu/HorizontalNavMenu.vue'

// Vertical Menu
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
//import mixinLayoutHorizontal from '@core/layouts/layout-horizontal/mixinLayoutHorizontal'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'

import UserDropdown from '@/components/UserDropdown.vue'

export default {
  components: {
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,

    BNavbar, BNavbarNav, BImg, BLink,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,
    BRow, BCol, BContainer, BButton,
    // Vertical Menu
    VerticalNavMenu,
    UserDropdown
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // App Name
    const { appName, appLogoImage, appLogoSmallImage } = $themeConfig.app

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,

      // Scroll Listeners
      scrolledTo,

      appName,
      appLogoImage,
      appLogoSmallImage
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";

.navbar {
  background-color: #213266;

  .logo {
    width: 80%;
  }
}

</style>