<template>
  <b-nav-item-dropdown id="profile-dropdown" right toggle-class="dropdown-user-link" class="dropdown-user dropdown-toggle-no-carret" v-if="$auth.user && $auth.db.hashid">
    <template #button-content>
      <div class="user-nav float-left">
        <p class="user-name font-weight-bolder mb-0">
          {{ $auth.db.firstname }} {{ $auth.db.lastname }}
        </p>
        <span class="user-status">{{ $auth.db.email }}</span>
      </div>
      <b-avatar size="40" v-bind:src="$auth.db.image ? $auth.db.image_base64 : ''" variant="light-primary" badge class="badge-minimal float-right" badge-variant="success">

      </b-avatar>
    </template>

    <b-dropdown-item link-class="d-flex align-items-center">
      <router-link to="/profiel">
        <feather-icon size="16" icon="UserIcon" class="mr-50" />
        <span>Profiel</span>
      </router-link>
    </b-dropdown-item>
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout()">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      profile_image: null,
      avatarText
    }
  },
  mounted() {

  },
  methods: {
    logout() {
      let _this = this;
      this.$http.post("/logout").then(() => {
        _this.$auth.logout();
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.user-nav {
  color: white;
  font-size: 0.8em;
  text-align: right;
  margin-right: 1em;
}
</style>

<style lang="scss">
#profile-dropdown .dropdown-toggle::after {
  background: none !important;
  opacity: 0 !important;
}

#profile-dropdown .dropdown-toggle-no-carret::after {
  display: none !important;
}

#profile-dropdown .dropdown-user-link::after {
  display: none !important;
}
</style>