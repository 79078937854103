<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © {{ new Date().getFullYear() }}
      Familiebank
      <span class="">, Alle rechten voorbehouden</span>
      <span class="">, <a href="/files/terms/Algemene-Voorwaarden-Familiebank-2024.pdf" target="_blank">Algemene Voorwaarden</a></span>
    </span>

    <span class="float-md-right d-none d-md-block">Hulp nodig? Klik hier
      <feather-icon icon="HelpCircleIcon" size="21" class="text-danger stroke-current" />
    </span>
  </p>
</template>

<script>
// import { BLink } from 'bootstrap-vue'

export default {
  components: {
    // BLink,
  },
}
</script>
