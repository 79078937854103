var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"horizontal-layout",staticStyle:{"height":"inherit"},attrs:{"data-col":_vm.isNavMenuHidden ? '1-column' : null}},[_c('b-navbar',{staticClass:"d-flex justify-content-center navbar-fixed"},[_c('span',{staticClass:"brand-logo d-flex justify-content-center"},[_c('b-img',{staticClass:"logo",attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1),_c('b-navbar-nav',{staticClass:"nav align-items-center ml-auto",staticStyle:{"z-index":"1000"}},[_c('user-dropdown')],1)],1),_c('div',{staticClass:"horizontal-menu-wrapper"},[_c('div',{staticClass:"header-navbar navbar navbar-horizontal navbar-light navbar-shadow menu-border",class:[_vm.navbarMenuTypeClass]},[_c('b-container',{staticClass:"d-flex aling-items-center justify-content-center"},[_c('b-row',{},[_c('b-col',{staticClass:"d-flex flex-column flex-sm-row",attrs:{"cols":"12","sm":""}},[_c('router-link',{staticClass:"mt-1 mt-sm-0 m-sm-1",attrs:{"to":"/overzicht","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-button',{class:isActive ? 'active': '',attrs:{"variant":"info","active":isActive},on:{"click":navigate}},[_vm._v(" Overzicht ")])]}}])}),(_vm.$auth.user && _vm.$auth.hasRole(['admin', 'superadmin']))?_c('router-link',{staticClass:"mt-1 mt-sm-0 m-sm-1",attrs:{"to":"/familiebeheer/relaties","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-button',{class:isActive ? 'active': '',attrs:{"variant":"info","active":isActive},on:{"click":navigate}},[_vm._v(" Familiebeheer ")])]}}],null,false,2621936653)}):_vm._e(),(_vm.$auth.user && _vm.$auth.hasRole(['sender', 'superadmin']))?_c('router-link',{staticClass:"mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1",attrs:{"to":"/jaaropgave","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-button',{class:isActive ? 'active': '',attrs:{"variant":"info","active":isActive},on:{"click":navigate}},[_vm._v(" Jaaropgave ")])]}}],null,false,1270669089)}):_vm._e(),(_vm.$auth.user && _vm.$auth.hasRole(['superadmin']))?_c('router-link',{staticClass:"mt-1 mt-sm-0 mb-1 mb-sm-0 m-sm-1",attrs:{"to":"/admin","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('b-button',{class:isActive ? 'active': '',attrs:{"variant":"secondary","active":isActive},on:{"click":navigate}},[_vm._v(" Admin ")])]}}],null,false,174668200)}):_vm._e()],1)],1)],1)],1)]),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c(_vm.layoutContentRenderer,{key:_vm.layoutContentRenderer === 'layout-content-renderer-left' ? _vm.$route.meta.navActiveLink || _vm.$route.name : null,tag:"component",scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})],1),_c('footer',{staticClass:"footer footer-light",class:[_vm.footerTypeClass]},[_vm._t("footer",function(){return [_c('app-footer')]})],2),_vm._t("customizer")],2)}
var staticRenderFns = []

export { render, staticRenderFns }